import React from "react";
import SearchResultPage from "../../templates/search-results-template";
// import {
//     parseSearchUrl,
//     savedSearchParams,
//     propertyH1
// } from "../../../Components/common/site/utils";

const SearchResultSales = (props) => {

    const fullpath = props.location.pathname
    const location = props.location
    const fullpathone = props.location
    // const searchParams = parseSearchUrl("sales", fullpath);

    return (
        <React.Fragment>
            <SearchResultPage 
                locationname="/new-homes-to-rent/" 
                pcategorytype="new_developments" 
                ptype="lettings" 
                ptypetag="to-rent" 
                //pstatustype="For Sale" 
                pstatustype= {['To Let']}
                fullpathone={fullpathone} 
                fullpathname={fullpath}
                {...props}
                location={location}
            />
        </React.Fragment>
    );
};

export default SearchResultSales;
